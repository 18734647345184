const
  SHOW_MODAL_CLASS_MODIFIER = 'image-modal--show',
  ROOT_ELEMENT_CLASS = 'image-modal',
  IMAGE_CONTAINER_CLASS = 'image-modal__img img',
  GALLERY_ITEM_CLASS = 'photo-gallery__item',
  CLOSE_BUTTON_CLASS = 'image-modal__close-btn';

export {ROOT_ELEMENT_CLASS, IMAGE_CONTAINER_CLASS};

export default class ImageModal {

    constructor($rootElement) {
        console.log('Image Modal loaded');
        this.$rootElement = $rootElement;
        this.$imageContainer = $rootElement.querySelector(`.${IMAGE_CONTAINER_CLASS}`);
        this.bindEvents();
    }

    bindEvents() {
        document
            .querySelectorAll(`.${GALLERY_ITEM_CLASS}`)
            .forEach(it => it.addEventListener('click', this.onClicked.bind(this)));

        console.log(document.querySelectorAll(`.${GALLERY_ITEM_CLASS}`));

        // TODO Add close button
        document
            .querySelector(`.${CLOSE_BUTTON_CLASS}`)
            .addEventListener('click', this.onCloseClicked.bind(this));
        
    }

    onClicked(evt) {
        evt.preventDefault();
        console.log('Gallery item clicked', evt.currentTarget);
        const target = evt.currentTarget.querySelector('img');

        // Copy the image information
        this.$imageContainer.src = target.src;
        this.$imageContainer.alt = target.alt;
    

        // Show modal
        this.$rootElement.classList.add(SHOW_MODAL_CLASS_MODIFIER);
    }

    onCloseClicked(evt) {
        evt.preventDefault();
        console.log('Modal close button clicked');
        this.$rootElement.classList.remove(SHOW_MODAL_CLASS_MODIFIER);
    }
}
